.profile-card {
    max-width: 400px;
    margin: 40px auto;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-card h5 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
}

.profile-content {
    display: flex;
    flex-direction: column;
}

.profile-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.profile-fields {
    width: 70%;
}

.profile-fields .MuiTypography-body1 {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
}

.profile-fields .MuiTextField-root {
    margin-bottom: 16px;
}

.profile-fields .MuiOutlinedInput-root {
    border-radius: 4px;
}

.profile-fields .MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
}

.profile-avatar {
    width: 100px !important;
    height: 100px !important;
    background-color: #9c27b0 !important;
    color: white !important;
    font-size: 36px !important;
}

.profile-username {
    font-size: 14px;
    color: #666;
    text-align: right;
    margin-bottom: 16px;
}

.profile-action {
    display: flex;
    justify-content: center;
}

.profile-action .MuiButton-root {
    background-color: #9c27b0 !important;
    color: white !important;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 8px 24px !important;
}

.profile-action .MuiButton-root:hover {
    background-color: #7b1fa2 !important;
}