.signup-section {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-section h1 {
    font-size: 24px;
    margin-bottom: 8px;
    color: #333;
}

.signup-section > p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.signup-form {
    display: flex;
    flex-direction: column;
}

.MuiTextField-root {
    margin-bottom: 16px;
}

.MuiInputLabel-root {
    color: #666;
}

.MuiOutlinedInput-root {
    border-radius: 4px;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
}

.MuiLink-root {
    color: #9c27b0;
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 16px;
    align-self: flex-start;
}

.form-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.submit-signup {
    background-color: #9c27b0 !important;
    color: white !important;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 10px 0 !important;
}

.google-signup {
    background-color: white !important;
    color: #333 !important;
    border: 1px solid #ddd !important;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 10px 0 !important;
}

.MuiButton-root:hover {
    opacity: 0.9;
}

.error-message {
    color: #f44336;
    margin-bottom: 16px;
}

.signup-section > .MuiTypography-root:last-child {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}

.link-text {
    color: #9c27b0;
    cursor: pointer;
    text-decoration: none;
}

.link-text:hover {
    text-decoration: underline;
}